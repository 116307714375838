import { Enum } from '@martin_hotell/rex-tils'

export type DeliveryTime = {
  deliveryBefore: string | null
  deliveryAfter: string | null
}

export interface OrderInterface {
  id: number
  uuid: string
  state: Order.State
  stateUpdatedAt?: string | null
  kind: Order.Kind
  serviceKind: Order.ServiceKind | null
  scheduledFor?: string | null
  createdAt: string
  submittedAt: string
  itemQuantityCount: number
  paymentMode?: string | null
  paymentAmount: string
  user: User
  origin: Origin
  destination: Destination
  mission: Mission | null
  items: ItemsEntity[]
  deliveryAttempts: number
  allowedPaymentTypes?: string[]
  partnerOrderId: string | null
  requiredDocuments?: string[]
  regionName: Region.Label
  trackingUrl: string | null
  deliveryTime: DeliveryTime
  weightKg: number | string
  confidence: number
  currentInternalOrderId: number
  onHoldReason: string | null
}
export interface CreateMissionInterface {
  missionId: string
  courier: string | null
  stateUpdatedAt?: string | null
  state: 'created' | 'assigned' | 'in_progress' | 'completed' | 'cancelled' | null
}
export interface User {
  id: number
  email: string
  fullname: string
}

export interface Origin {
  id: number
  contactName: string
  contactPhone: string
  arrivedAt: string
  finishedAt: string
  trackingToken: string
  trackingUrl: string
  address: Address
  checked: boolean
}

export interface Address {
  id: number
  address1: string
  country: string
  coordinates: Coordinates
  address2: string | null
  apartmentNumber: string | null
  buildingName: string | null
  town: string
}

export interface Zone {
  parent: string
  parent_group: string
  sector: string
}

export interface Coordinates {
  lat: number
  lng: number
}

export interface Destination {
  id: number
  contactName: string
  contactPhone: string
  arrivedAt?: null
  finishedAt?: null
  trackingToken: string
  trackingUrl: string
  checked: boolean
  address: Address
  zone: Zone
  emirate: Region.Label
}

export interface Courier {
  firstname: string
  email: string
}
export interface Mission {
  id: string
  kind: string
  state: string
  submittedAt: string
  courier?: Courier | null
}

export interface ItemsEntity {
  name: string
  quantity: number
  parcelBarcode: string
  weight: number | string
}

export interface ItemsWithScanned {
  name: string
  quantity: number
  parcelBarcode: string
  weight: number | string
  scanned?: boolean // used for validating merged orders scanning
}

export interface OrderWithScanned extends Omit<OrderInterface, 'items'> {
  items: ItemsWithScanned[]
}

type OrderStatesLabels = { [key in Order.State]: Order.StateLabel }
export const OrderStatesLabels: Readonly<OrderStatesLabels> = {
  collected: 'Collected',
  pending: 'Pending',
  requested: 'Requested',
  ready_for_collection: 'Ready for collection',
  out_for_collection: 'Out for collection',
  out_for_return: 'Out for return',
  received_at_depot: 'Received at depot',
  out_for_delivery: 'Out for delivery',
  delivery_complete: 'Delivery complete',
  delivery_failed: 'Delivery failed',
  collection_failed: 'Collection failed',
  return_to_origin: 'Return to origin',
  returned_to_origin: 'Returned to origin',
  cancelled: 'Cancelled',
  on_hold: 'On hold',
  at_depot: 'At depot',
  in_transit: 'In transit',
  scheduled: 'Scheduled',
}

type TransactionTypesLabels = { [key in TransactionType]: TransactionLabel }
export const TransactionTypesLabels: Readonly<TransactionTypesLabels> = {
  other: 'Other',
  atm: 'ATM',
  cash: 'Cash',
  refund: 'Refund',
  partner: 'Partner',
  personal: 'Personal',
  partner_paid_at_venue: 'Partner paid at venue',
  pfs_fraud_quiqee_fault: 'Fraud quiee fault',
  pfs_fraud_not_quiqee_fault: 'Fraud not quiqee fault',
  cash_on_delivery: 'Cash on delivery',
  card_on_delivery: 'Card on delivery',
}

type OrderPaymentTypes = { [key in Order.PaymentType]: Order.PaymentTypeLabel }
export const OrderPaymentTypes: Readonly<OrderPaymentTypes> = {
  paid_on_delivery: 'Paid on delivery',
  pre_paid: 'Pre paid',
}

type OrderKindLabels = { [key in Order.ServiceKind]: Order.ServiceKindLabel }
export const OrderKindLabels: Readonly<OrderKindLabels> = {
  partner_same_day: 'Same day',
  partner_next_day: 'Next day',
  partner_return: 'Return',
  partner_4hr: '4 hours',
  partner_store: 'Store',
  partner_fulfillment: 'Fulfillment',
  partner_q_commerce: 'Q-commerce',
}

type OrderKindShortCodes = {
  [key in Order.ServiceKind]: Order.ServiceKindLabelShort
}
export const OrderKindShortCodes: Readonly<OrderKindShortCodes> = {
  partner_same_day: 'SD',
  partner_next_day: 'ND',
  partner_return: 'RE',
  partner_4hr: '4HR',
  partner_store: 'STR',
  partner_fulfillment: 'FF',
  partner_q_commerce: 'QC',
}

export enum KindMapped {
  partnerSameDay = 'partner_same_day',
  partnerNextDay = 'partner_next_day',
  partnerReturn = 'partner_return',
  partner4hr = 'partner_4hr',
  partnerStore = 'partner_store',
  partnerFulfillment = 'partner_fulfillment',
  partnerQCommerce = 'partner_q_commerce',
}

export enum StateMapped {
  collected = 'collected',
  pending = 'pending',
  requested = 'requested',
  readyForCollection = 'ready_for_collection',
  outForCollection = 'out_for_collection',
  receivedAtDepot = 'received_at_depot',
  outForDelivery = 'out_for_delivery',
  deliveryComplete = 'delivery_complete',
  deliveryFailed = 'delivery_failed',
  collectionFailed = 'collection_failed',
  outForReturn = 'out_for_return',
  returnToOrigin = 'return_to_origin',
  returnedToOrigin = 'returned_to_origin',
  cancelled = 'cancelled',
  onHold = 'on_hold',
  atDepot = 'at_depot',
  inTransit = 'in_transit',
  scheduled = 'scheduled',
}

export enum onHoldReasons {
  unableToDispatchNow = 'Unable to Dispatch Now',
}

export const OrderKinds = Enum(
  'partner_same_day',
  'partner_next_day',
  'partner_return',
  'partner_4hr',
  'partner_store',
  'partner_fulfillment',
  'partner_q_commerce'
)

export const OrderStates = Enum(
  'collected',
  'pending',
  'requested',
  'ready_for_collection',
  'out_for_collection',
  'received_at_depot',
  'out_for_delivery',
  'delivery_complete',
  'delivery_failed',
  'collection_failed',
  'out_for_return',
  'return_to_origin',
  'returned_to_origin',
  'cancelled',
  'on_hold',
  'at_depot',
  'in_transit',
  'scheduled'
)
export type OrderStates = Enum<typeof OrderStates>
export type OrderKinds = Enum<typeof OrderKinds>
export type AddressChecked = true | false | undefined

export const AllowedOrderStatesForUpdate = Enum(
  OrderStates.ready_for_collection,
  OrderStates.collected,
  OrderStates.received_at_depot,
  OrderStates.return_to_origin,
  OrderStates.cancelled,
  OrderStates.on_hold,
  OrderStates.scheduled,
  OrderStates.at_depot,
  OrderStates.in_transit
)
export type AllowedOrderStatesForUpdate = Enum<typeof AllowedOrderStatesForUpdate>

export const FilterableOrderStates = Enum(
  OrderStates.pending,
  OrderStates.ready_for_collection,
  OrderStates.out_for_collection,
  OrderStates.collected,
  OrderStates.received_at_depot,
  OrderStates.out_for_delivery,
  OrderStates.delivery_failed,
  OrderStates.collection_failed,
  OrderStates.delivery_complete,
  OrderStates.at_depot,
  OrderStates.in_transit,
  OrderStates.on_hold,
  OrderStates.cancelled,
  OrderStates.scheduled,
  OrderStates.out_for_return,
  OrderStates.return_to_origin,
  OrderStates.returned_to_origin
)

export const FilterableOrderKinds = Enum(
  OrderKinds.partner_same_day,
  OrderKinds.partner_next_day,
  OrderKinds.partner_return,
  OrderKinds.partner_4hr,
  OrderKinds.partner_store,
  OrderKinds.partner_fulfillment,
  OrderKinds.partner_q_commerce
)

export type FilterableOrderStates = Enum<typeof FilterableOrderStates>

export type UpdateLocationAddressFormValues = Pick<
  Address,
  'address1' | 'address2' | 'apartmentNumber' | 'town' | 'country'
> & {
  coordinates: string
}

export type UpdateContactPhoneFormValues = {
  contactPhone: string
}
export type UpdatePaymentamountFormValues = {
  paymentAmount: string
  paymentMode: string
}

export type paymentMode = string | null | undefined
